import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Input, Table, Button, Tag } from "antd";
import moment from "moment";
import { Query } from "@apollo/react-components";
import { gql } from "apollo-boost";
import history from "../history";
import ChangeAccountStatus from "./ChangeAccountStatus";
import SetEnabledProcedures from "./SetEnabledProcedures";
import ChangePasswordExpired from "./ChangePasswordExpired";
import {PREFIX, SYNC_TARGET_API} from "../config";
import DeleteCaregiverButton from "./caregiver/DeleteCaregiverButton";
import SyncUserButton from "./SyncUserButton";

const QUERY = gql`
  query Caregivers($filter: FindFilter) {
    caregivers(filter: $filter) {
      total
      nodes {
        id
        firstName
        lastName
        email
        username
        lastAccessed
        status
        isPasswordExpired
        enabledProcedures {
          id
          procedureId
          name
          isEnabled
          createdAt
        }
        caregiver {
          id
          businessName
          shareCode
        }
      }
    }
  }
`;
const Container = styled.div`
  .actions {
    button {
      margin-left: 8px;
    }
  }
  .page-header {
    .page-title {
      text-align: center;
      font-weight: bold;
    }
    .page-search {
      text-align: right;
      margin-bottom: 20px;
      .ant-input-search {
        display: inline-block;
        width: inherit;
      }
    }
  }
`;

class Caregivers extends Component {
  state = {
    currentPage: 0,
    filter: {
      search: "",
      limit: 10,
      offset: 0,
    },
  };
  getStatusTagColor = (value) => {
    let color = "gray";
    if (value === "Closed" || value === "Locked") {
      color = "volcano";
    } else if (value === "Active") {
      color = "green";
    }

    return color;
  };

  render() {
    const { filter } = this.state;
    return (
      <Container>
        <div className={"page-header"}>
          <h2 className={"page-title"}>Manage Caregivers</h2>
          <div className={"page-search"}>
            <Input.Search
              placeholder={"Search..."}
              value={filter.search}
              onChange={(e) => {
                this.setState({
                  filter: {
                    ...filter,
                    search: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className={"page-content"}>
          <Query query={QUERY} variables={{ filter: filter }}>
            {({ error, loading, data, refetch }) => {
              const columns = [
                {
                  title: "ID",
                  dataIndex: "id",
                  key: "id",
                  width: 50,
                },
                {
                  title: "Full Name",
                  dataIndex: "name",
                  key: "name",
                  render: (_, row) => (
                    <span>{`${row.firstName} ${row.lastName}`}</span>
                  ),
                },
                {
                  title: "Business name",
                  dataIndex: "businessName",
                  key: "businessName",
                  render: (_, row) => (
                    <Fragment> {row.caregiver.businessName}</Fragment>
                  ),
                },
                {
                  title: "Contact email",
                  dataIndex: "email",
                  key: "email",
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  render: (_, row) => (
                    <Tag color={this.getStatusTagColor(row.status)}>
                      {row.status}
                    </Tag>
                  ),
                },
                {
                  title: "Password expired",
                  dataIndex: "isPasswordExpired",
                  key: "isPasswordExpired",
                  width: 100,
                  render: (_, row) => (
                    <ChangePasswordExpired
                      isCaregiver={true}
                      query={QUERY}
                      variables={{ filter }}
                      user={row}
                    />
                  ),
                },
                {
                  title: "Last accessed",
                  dataIndex: "lastAccessed",
                  key: "lastAccess",
                  render: (_, row) => (
                    <span>
                      {row.lastAccessed
                        ? moment(row.lastAccessed).fromNow()
                        : ""}
                    </span>
                  ),
                },
                {
                  title: "Actions",
                  key: "actions",
                  render: (_, record) => {
                    return (
                      <div className={"actions"} style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "3px",
                      }}>
                        <Button
                          size="small"
                          onClick={() => {
                            history.push(`${PREFIX}/caregivers/${record.id}`);
                          }}
                        >
                          View
                        </Button>
                        <ChangeAccountStatus
                          isCaregiver={true}
                          query={QUERY}
                          variables={{ filter }}
                          user={record}
                        />
                        <SetEnabledProcedures
                          isCaregiver={true}
                          query={QUERY}
                          variables={{ filter }}
                          user={record}
                        />
                        <DeleteCaregiverButton
                          id={record.id}
                          onDeleteSuccess={() => {
                            if (refetch) {
                              refetch();
                            }
                          }}
                        />
                        {SYNC_TARGET_API && (
                          <SyncUserButton user={record}/>
                        )}
                      </div>
                    );
                  },
                },
              ];
              if (loading) return <div>Loading...</div>;
              if (error) return <div>{error.toString()}</div>;

              return (
                <Table
                  rowKey={"id"}
                  dataSource={data.caregivers.nodes}
                  columns={columns}
                  pagination={{
                    current: this.state.currentPage,
                    pageSize: this.state.filter.limit,
                    total: data.caregivers.total,
                    onChange: (page) => {
                      this.setState({
                        currentPage: page,
                        filter: {
                          ...filter,
                          offset: (page - 1) * filter.limit,
                        },
                      });
                    },
                  }}
                />
              );
            }}
          </Query>
        </div>
        <div className={"page-footer"}></div>
      </Container>
    );
  }
}

export default Caregivers;
