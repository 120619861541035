import React from 'react';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {Checkbox} from 'antd'

const QUERY = gql`
  query getEnableAutoRegistrationApproval{
    getEnableAutoRegistrationApproval
  }
`

const MUTATION = gql`mutation enableAutoRegistrationApproval($value: Boolean!){
  enableAutoRegistrationApproval(value:$value)
}`

const EnableAutomaticRegistrationAutoApproval = () => {
  const [enableAutoRegistrationApproval] = useMutation(MUTATION)
  const {data, loading, client} = useQuery(QUERY)
  if (loading) return null
  let isEnableAutoRegistrationApproval = false
  if (data && data.getEnableAutoRegistrationApproval) {
    isEnableAutoRegistrationApproval = true
  }
  return (
    <Checkbox onChange={(e) => {
      const {checked} = e.target
      enableAutoRegistrationApproval({
        variables: {value: checked},
        optimisticResponse: {
          __typename: "Mutation",
          enableAutoRegistrationApproval: checked
        }
      })
      client.writeQuery({
        query: QUERY,
        data: {
          getEnableAutoRegistrationApproval: checked
        }
      })

    }} checked={isEnableAutoRegistrationApproval}>
      Enable automatic registration approval
    </Checkbox>
  );
};

export default EnableAutomaticRegistrationAutoApproval;
