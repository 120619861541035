import React, {Component} from 'react'
import {Form, Input, Icon, Button, Card, notification} from 'antd'
import styled from 'styled-components'
import {Mutation} from '@apollo/react-components'
import {gql} from 'apollo-boost'
import {setAppToken} from '../helper'
import {AppContext} from '../context'
import history from '../history'
import {Link} from 'react-router-dom'
import {PREFIX} from "../config";

const LOGIN_MUTATION = gql`
    mutation login($input: Login!){
        login(input:$input){
            id
            user{
                id
                firstName
                lastName
                namePrefix
                nameSuffix
                phone
                email
                username
                roles
            }
            expiredAt
        }
    }
`
const Container = styled.div`
   display: flex;
   justify-content: center;
  .login-container{
    width: 320px;
    @media(min-width: 768px){
      width: 400px;
    }
  }
  .ant-card-head-title{
    text-align: center;
  }
`

class LoginForm extends Component {
  state = {
    loading: false,
  }

  render() {
    const {getFieldDecorator} = this.props.form
    return (
        <Container>
          <AppContext.Consumer>
            {({setCurrentUser}) => (
                <Mutation

                    onCompleted={({login}) => {
                      // set to localStore
                      setAppToken(login)
                      // set to app context
                      setCurrentUser(login.user)

                      // redirect to home page
                      history.push(`${PREFIX}/`)
                    }}
                    onError={(err) => {
                      notification.error({
                        message: err.toLocaleString().
                            replace('Error: GraphQL error:', ''),
                      })
                      this.setState({loading: false})
                    }}
                    mutation={LOGIN_MUTATION}>
                  {(login) => (
                      <Card title={'Sign In'} className="login-container">
                        <Form onSubmit={(e) => {
                          e.preventDefault()
                          this.props.form.validateFields((err, values) => {
                            if (!err) {
                              if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  values.username)) {
                                values.email = values.username
                                delete values.username
                              }
                              this.setState({
                                loading: true,

                              }, () => {
                                login({
                                  variables: {
                                    input: values,
                                  },
                                })
                              })
                            }
                          })

                        }}>

                          <Form.Item label={'Username or email address'}>
                            {getFieldDecorator('username', {
                              initialValue: '',
                              rules: [
                                {
                                  required: true,
                                  message: 'Username is required',
                                }],
                            })(
                                <Input
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    prefix={<Icon
                                        type="user"
                                        style={{color: 'rgba(0,0,0,.25)'}}/>}/>,
                            )}
                          </Form.Item>
                          <Form.Item label={'Password'}>
                            {getFieldDecorator('password', {
                              initialValue: '',
                              rules: [
                                {
                                  required: true,
                                  message: 'Password is required',
                                }],
                            })(
                                <Input.Password
                                    autoCapitalize={'off'}
                                    autoCorrect={'off'}
                                    prefix={<Icon
                                        type="lock"
                                        style={{color: 'rgba(0,0,0,.25)'}}/>}/>,
                            )}
                          </Form.Item>
                          <Form.Item style={{textAlign: 'center'}}>
                            <Button
                                size="large"
                                style={{width: '100%'}}
                                loading={this.state.loading}
                                htmlType="submit"
                                type="primary">Login</Button>
                          </Form.Item>
                          <p>
                            <Link to={`${PREFIX}/forgot-password`}>Forgot
                              password?</Link>
                          </p>
                        </Form>
                      </Card>
                  )}
                </Mutation>
            )}
          </AppContext.Consumer>
        </Container>
    )
  }
}

export default Form.create({name: 'form'})(LoginForm)
