import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Input, Button, notification} from 'antd'
import styled from 'styled-components'
import {ApolloConsumer} from '@apollo/react-components'
import {gql} from 'apollo-boost'

const MUTATION = gql`
    mutation updateUser($input: UpdateUser!) {
        updateUser(input: $input) {
            email
        }
    }
`

const Container = styled.span`
  display: flex;
  button{
  margin-left: 10px;
  }
`

class UserEmailEditable extends Component {
  state = {
    isEdit: false,
    value: '',
  }

  componentDidMount() {
    this.setState({
      value: this.props.defaultValue,
    })
  }

  render() {
    const {isEdit} = this.state

    return (
        <ApolloConsumer>
          {(client) => (
              <React.Fragment>
                {
                  isEdit ?
                      <Container>
                        <Input
                            value={this.state.value}
                            onChange={e => this.setState(
                                {value: e.target.value})}/>
                        <Button
                            type="primary"
                            onClick={() => {
                              client.mutate({
                                mutation: MUTATION,
                                variables: {
                                  input: {
                                    id: this.props.userId,
                                    email: this.state.value,
                                  },
                                },
                              }).then(() => {
                                const value = this.state.value
                                this.setState({isEdit: false}, () => {
                                  if (this.props.onChange) {
                                    this.props.onChange(value)
                                  }
                                })
                                notification.success(
                                    {message: 'Email has been changed.'})
                              }).catch((e) => {
                                notification.error(
                                    {message: e.toLocaleString()})
                              })
                            }}>Save</Button>
                        <Button
                            onClick={() => {
                              this.setState(
                                  {
                                    value: this.props.defaultValue,
                                    isEdit: false,
                                  })
                            }}>Cancel</Button>
                      </Container> :
                      <span>{this.state.value} <Button onClick={() => {
                        this.setState({isEdit: true})
                      }} type="link" icon="edit"/></span>
                }
              </React.Fragment>
          )}
        </ApolloConsumer>
    )
  }
}

UserEmailEditable.propTypes = {
  userId: PropTypes.any,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
}

export default UserEmailEditable
