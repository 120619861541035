import React, { Component } from "react";
import styled from "styled-components";
import { Query } from "@apollo/react-components";
import { gql } from "apollo-boost";
import { Table, Button, Input, Select } from "antd";
import SearchClientAutocomplete from "./SearchClientAutocomplete";
import { Link } from "react-router-dom";
import moment from "moment";
import DeleteProcedureDataButton from "./DeleteProcedureDataButton";
import EditProcedureDataButton from "./EditProcedureDataButton";
import {PREFIX} from "../config";

const Q = gql`
  query findData($filter: DataFilter) {
    procedures {
      id
      name
    }
    findData(filter: $filter) {
      total
      nodes {
        id
        procedureComment
        data
        rawData1
        rawData2
        createdAt
        testedDate
        procedure {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
const Container = styled.div`
  .page-header {
    margin-bottom: 20px;
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
    }
    .page-title {
      margin-right: 20px;
    }
  }
  .actions {
    button {
      margin-left: 8px;
    }
  }
  .filter-container {
    margin-bottom: 20px;
    @media (min-width: 991px) {
      display: flex;
      .filter-item {
        margin-right: 10px;
      }
    }
    .filter-item {
      margin-bottom: 8px;
      @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        .filter-label {
          margin-right: 10px;
        }
      }
      .selected-client {
        font-weight: bold;
      }
    }
  }
  .actions {
    button {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
`;

class ProcedureData extends Component {
  state = {
    selectedClient: null,
    procedures: [],
    filter: {
      search: "",
      limit: 20,
      offset: 0,
      procedureId: null,
      clientId: null
    }
  };

  render() {
    const { filter, selectedClient } = this.state;

    const variables = { filter };

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "name"
      },
      {
        title: "Client",
        dataIndex: "user",
        key: "user",
        render: (_, record) => (
          <Link
            to={`${PREFIX}/clients/${record.user.id}?destination=/procedure-data`}
          >{`${record.user.firstName} ${record.user.lastName}`}</Link>
        )
      },
      {
        title: "Procedure",
        dataIndex: "procedure",
        key: "procedure",
        render: (_, record) => <span>{`${record.procedure.name}`}</span>
      },
      {
        title: "Comment",
        dataIndex: "procedureComment",
        key: "procedureComment"
      },
      {
        title: "Tested date",
        render: (_, record) => (
          <div>{moment.unix(record.testedDate).format("LLL")}</div>
        )
      },
      {
        title: "Created",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_, record) => (
          <span>{moment(record.createdAt).format("LLL")}</span>
        )
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (_, record) => (
          <div className={"actions"}>
            <EditProcedureDataButton
              onCompleted={(client, data) => {
                const cache = client.readQuery({
                  query: Q,
                  variables: variables
                });
                client.writeQuery({
                  query: Q,
                  variables: variables,
                  data: {
                    ...cache,
                    findData: {
                      ...cache.findData,
                      total: cache.findData.total - 1,
                      nodes: cache.findData.nodes.map(n => {
                        if (n.id === data.id) {
                          return data;
                        }
                        return n;
                      })
                    }
                  }
                });
              }}
              data={record}
            />
            <DeleteProcedureDataButton
              onCompleted={client => {
                try {
                  const cache = client.readQuery({
                    query: Q,
                    variables: variables
                  });

                  client.writeQuery({
                    query: Q,
                    variables: variables,
                    data: {
                      ...cache,
                      findData: {
                        ...cache.findData,
                        total: cache.findData.total - 1,
                        nodes: cache.findData.nodes.filter(
                          item => item.id !== record.id
                        )
                      }
                    }
                  });
                } catch (e) {
                  console.log(e);
                }
              }}
              data={record}
            />
          </div>
        )
      }
    ];
    return (
      <Container>
        <div className={"page-header"}>
          <h2 className="page-title">Procedures Data</h2>
        </div>
        <div className={"filter-container"}>
          <div className={"filter-item"}>
            <Input.Search
              onChange={e => {
                this.setState({
                  filter: { ...filter, search: e.target.value }
                });
              }}
              placeholder={"Search..."}
            />
          </div>
          <div className={"filter-item"}>
            <div className={"filter-label"}>Filter by client:</div>
            {selectedClient ? (
              <div className={"selected-client"}>
                <span>{`${selectedClient.firstName} ${selectedClient.lastName}`}</span>
                <Button
                  onClick={() => {
                    this.setState({
                      selectedClient: null,
                      filter: {
                        ...filter,
                        clientId: null
                      }
                    });
                  }}
                  title={"Remove filter"}
                  icon="close"
                  type="link"
                />
              </div>
            ) : (
              <SearchClientAutocomplete
                onChange={user => {
                  this.setState({
                    selectedClient: user,
                    filter: {
                      ...filter,
                      clientId: user.client.id
                    }
                  });
                }}
                placeholder={"Search client..."}
              />
            )}
          </div>
          <div className="filter-item">
            <Select
              onChange={id => {
                this.setState({ filter: { ...filter, procedureId: id } });
              }}
              style={{ width: 300 }}
              value={filter.procedureId}
            >
              <Select.Option value={null}>Select procedure</Select.Option>
              {this.state.procedures.map((p, index) => (
                <Select.Option key={index} value={p.id}>
                  {p.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <Query
          onCompleted={res => {
            this.setState({
              procedures: res.procedures
            });
          }}
          query={Q}
          variables={variables}
        >
          {({ data, error, loading }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>{error.toString()}</div>;

            return (
              <Table
                rowKey={"id"}
                dataSource={data.findData.nodes}
                columns={columns}
                pagination={{
                  current: this.state.currentPage,
                  pageSize: this.state.filter.limit,
                  total: data.findData.total,
                  onChange: page => {
                    this.setState({
                      currentPage: page,
                      filter: {
                        ...filter,
                        offset: (page - 1) * filter.limit
                      }
                    });
                  }
                }}
              />
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default ProcedureData;
