export const getAppToken = () => {
  const localStoreToken = localStorage.getItem('appToken')
  if (localStoreToken) {
    return JSON.parse(localStoreToken)
  }
  return null

}

export const setAppToken = (token) => {
  if (!token) {
    localStorage.removeItem('appToken')
  } else {
    localStorage.setItem('appToken', JSON.stringify(token))
  }

}