import React, { Component } from "react";
import { Query } from "@apollo/react-components";
import gql from "graphql-tag";
import { Table, Tag, Input, Button } from "antd";
import moment from "moment";
import styled from "styled-components";
import ChangeAccountStatus from "./ChangeAccountStatus";
import history from "../history";
import SetEnabledProcedures from "./SetEnabledProcedures";
import ChangePasswordExpired from "./ChangePasswordExpired";
import {PREFIX, SYNC_TARGET_API} from "../config";
import DeleteClientButton from "./client/DeleteClientButton";
import SyncUserButton from "./SyncUserButton";

const QUERY = gql`
  query clients($filter: FindFilter) {
    clients(filter: $filter) {
      total
      nodes {
        id
        firstName
        lastName
        email
        username
        lastAccessed
        status
        isPasswordExpired
        enabledProcedures {
          id
          procedureId
          name
          isEnabled
          createdAt
        }
        client {
          id
          caregiverRegistrationNumber
          gender
          dateOfBirth
          heathNotes
          preferredBreathRate
          audioType
          audioGuide
          audioFile
          disclaimerAccepted
          lastTested
        }
      }
    }
  }
`;

const Container = styled.div`
  .page-header {
    margin-bottom: 20px;
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
    }
    .page-title {
      margin-right: 20px;
    }
  }
  .actions {
    button {
      margin-left: 8px;
    }
  }
`;

class Clients extends Component {
  state = {
    currentPage: 0,
    filter: {
      search: "",
      limit: 10,
      offset: 0,
    },
  };

  getStatusTagColor = (value) => {
    let color = "gray";
    if (value === "Closed" || value === "Locked") {
      color = "volcano";
    } else if (value === "Active") {
      color = "green";
    }

    return color;
  };

  render() {
    const { filter } = this.state;
    return (
      <Container>
        <div className={"page-header"}>
          <h2 className={"page-title"}>Manage Clients</h2>
          <div className={"search-container"}>
            <Input.Search
              onChange={(e) => {
                this.setState({
                  filter: {
                    ...filter,
                    search: e.target.value,
                  },
                });
              }}
              value={filter.search}
              placeholder={"Search..."}
            />
          </div>
        </div>
        <Query query={QUERY} variables={{ filter: filter }}>
          {({ error, loading, data, refetch }) => {
            const columns = [
              {
                title: "ID",
                dataIndex: "name",
                key: "id",
                render: (text, row) => <span>{row.id}</span>,
                width: 50,
              },
              {
                title: "Full name",
                dataIndex: "name",
                key: "name",
                width: 180,
                render: (text, row) => (
                  <span>{`${row.firstName} ${row.lastName}`}</span>
                ),
              },
              {
                title: "Gender",
                dataIndex: "gender",
                key: "gender",
                render: (text, row) => <span>{`${row.client.gender}`}</span>,
                width: 90,
              },
              {
                title: "Contact email",
                dataIndex: "email",
                key: "email",
                width: 270,
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                width: 80,
                render: (_, row) => (
                  <Tag color={this.getStatusTagColor(row.status)}>
                    {row.status}
                  </Tag>
                ),
              },
              {
                title: "Password expired",
                dataIndex: "isPasswordExpired",
                key: "isPasswordExpired",
                width: 100,
                render: (_, row) => (
                  <ChangePasswordExpired
                    query={QUERY}
                    variables={{ filter }}
                    user={row}
                  />
                ),
              },
              {
                title: "Last accessed",
                dataIndex: "lastAccessed",
                key: "lastAccess",
                width: 100,
                render: (_, row) => (
                  <span>
                    {row.lastAccessed ? moment(row.lastAccessed).fromNow() : ""}
                  </span>
                ),
              },

              {
                key: "actions",
                title: "Actions",
                render: (_, record) => {
                  return (
                    <div className={"actions"} style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '3px'
                    }}>
                      <Button
                        size="small"
                        onClick={() => {
                          history.push(`${PREFIX}/clients/${record.id}`);
                        }}
                      >
                        View
                      </Button>
                      <ChangeAccountStatus
                        query={QUERY}
                        variables={{ filter }}
                        user={record}
                      />
                      <SetEnabledProcedures
                        query={QUERY}
                        variables={{ filter }}
                        user={record}
                      />
                      <DeleteClientButton
                        id={record.id}
                        onDeleteSuccess={() => {
                          if (refetch) {
                            refetch();
                          }
                        }}
                      />
                      {SYNC_TARGET_API && (
                        <SyncUserButton user={record}/>
                      )}
                    </div>
                  );
                },
              },
            ];
            if (loading) return <div>Loading...</div>;
            if (error) return <div>{error.toString()}</div>;
            return (
              <Table
                scroll={{ x: 1000 }}
                rowKey={"id"}
                dataSource={data.clients.nodes}
                columns={columns}
                pagination={{
                  current: this.state.currentPage,
                  pageSize: this.state.filter.limit,
                  total: data.clients.total,
                  onChange: (page) => {
                    this.setState({
                      currentPage: page,
                      filter: {
                        ...filter,
                        offset: (page - 1) * filter.limit,
                      },
                    });
                  },
                }}
              />
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default Clients;
